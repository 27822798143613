<template>
  <div class="nevrology">
    <TopBlock
      class="nevrology-banner"
      :title="'Современная неврология: доказательная медицина и клиническая практика'"
      :description="'РАСПОЗНАЕМ и ЛЕЧИМ: от орфанных заболеваний до частных вопросов рутинной практики'"
      :images="[
        {
          src: require('@/assets/img/nevro/nevro_mob.jpg'),
          media: '(max-width: 767px)',
        },
        {
          src: require('@/assets/img/nevro/nevro_tablet.jpg'),
          media: '(max-width: 1220px)',
        },
        {
          src: require('@/assets/img/nevro/nevro.jpg'),
          media: '(max-width: 99999px)',
        },
      ]"
    />
    <Breadcrumbs :pages="[{ name: 'Современная неврология' }]" />
    <div class="content">
      <div class="nevrology__row">
        <div class="nevrology__aside">
          <div class="nevrology__aside-title">
            Заболевания и вопросы из практики
          </div>
          <div
            class="nevrology__aside-item"
            @click="activeItem = 0"
            :class="{ 'nevrology__aside-item_active': activeItem === 0 }"
          >
            <div class="nevrology__aside-item-title">
              ВсмATTRиваемся в полинейропатию
            </div>
            <div class="nevrology__aside-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6L27 16L17 26"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M25 16L5 16"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div
            class="nevrology__aside-item"
            @click="activeItem = 1"
            :class="{ 'nevrology__aside-item_active': activeItem === 1 }"
          >
            <div class="nevrology__aside-item-title">
              ХСН: "под прикрытием" инсульта
            </div>
            <div class="nevrology__aside-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6L27 16L17 26"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M25 16L5 16"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div
            class="nevrology__aside-item"
            @click="activeItem = 2"
            :class="{ 'nevrology__aside-item_active': activeItem === 2 }"
          >
            <div class="nevrology__aside-item-title">
              Терапия боли: упускаемый фокус внимания
            </div>
            <div class="nevrology__aside-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6L27 16L17 26"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M25 16L5 16"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="nevrology__body">
          <div
            class="nevrology__aside-item d-lg-none"
            @click="changeItem(0)"
            v-if="activeItem !== 0"
          >
            <div class="nevrology__aside-item-title">
              ВсмATTRиваемся в полинейропатию
            </div>
            <div class="nevrology__aside-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6L27 16L17 26"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M25 16L5 16"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div class="nevrology__body-item" v-if="activeItem === 0">
            <div class="nevrology__body-item-banner">
              <img
                class="nevrology__body-item-banner-img"
                :src="require('@/assets/img/nevro/1.jpg')"
              />
              <div class="nevrology__body-item-banner-title">
                ВсмATTRиваемся <br />в полинейропатию
              </div>
            </div>
            <div class="nevrology__body-item-text">
              Наследственный транстиретиновый амилоидоз (ATTR-амилоидоз) относят
              к орфанной патологии с поздним дебютом. Его диагностика сложна
              из-за неспецифичных симптомов, «замаскированных» под другие
              заболевания, такие как ХВДП, поясничная и крестцовая
              радикулопатия, боковой амиотрофический склероз и
              др<sup>1</sup>.<br /><br />
              ATTR-амилоидоз неуклонно прогрессирует, и без адекватной терапии
              заканчивается летальным исходом через 5–15 лет у пациентов с
              полинейропатией (ПН) и через 2–6 лет у пациентов с сопутствующей
              или преобладающей кардиомиопатией (КМП)<sup>2,3</sup>. Поэтому
              пациенты с ATTR-амилоидозом нуждаются в безотлагательном
              патогенетическом лечении.<br /><br />
              Видео с экспертами и клинические разборы реальных пациентов
              помогут узнать больше о лабораторной и инструментальной
              диагностике, классификации ATTR-амилоидоза и возможностях терапии.
            </div>
            <a
              href="https://az-most.ru/education/vsmattrivaemsya-v-polinejropatiyu"
              target="_blank"
              class="
                nevrology__body-item-button
                button button_pink
                d-inline-flex
                mt-auto
              "
            >
              Подробнее
            </a>
          </div>
          <div
            class="nevrology__aside-item d-lg-none"
            @click="changeItem(1)"
            v-if="activeItem !== 1"
          >
            <div class="nevrology__aside-item-title">
              ХСН: "под прикрытием" инсульта
            </div>
            <div class="nevrology__aside-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6L27 16L17 26"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M25 16L5 16"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div class="nevrology__body-item" v-if="activeItem === 1">
            <div class="nevrology__body-item-banner">
              <img
                class="nevrology__body-item-banner-img"
                :src="require('@/assets/img/nevro/2.jpg')"
              />
              <div class="nevrology__body-item-banner-title">
                ХСН: "под прикрытием" <br />инсульта
              </div>
            </div>
            <div class="nevrology__body-item-text">
              Большинство пациентов врача-невролога часто имеют много
              сопутствующих хронических заболеваний, что значимо снижает
              качество жизни и ухудшает дальнейший прогноз. Единство
              патофизиологических процессов таких заболеваний, как АГ, ХСН, СД2
              и ХБП, приводит к формированию ренокардиоцеребрального континуума,
              определяющего прогрессирование полиорганных изменений и
              осложнений<sup>4,5</sup>.
              <br />
              <br />
              Например, у пациентов с ХСН, даже при отсутствии ФП, риск инсульта
              выше, чем в общей популяции<sup>6</sup>. ХБП связана с увеличением
              риска как первичного, так и повторного инсульта, а у пациентов с
              СД 2-го типа он занимает 2–3 место по частоте
              смерти<sup>7,8</sup>.
              <br />
              <br />
              Учитывая высокую коморбидность и необходимость персонализировать
              терапию, курация таких пациентов должна осуществляться несколькими
              специалистами: терапевт, кардиолог, эндокринолог, нефролог.
            </div>
            <a
              href="https://az-most.ru/pod_prikrutiem"
              target="_blank"
              class="
                nevrology__body-item-button
                button button_pink
                d-inline-flex
                mt-auto
              "
            >
              Подробнее
            </a>
          </div>
          <div
            class="nevrology__aside-item d-lg-none"
            @click="changeItem(2)"
            v-if="activeItem !== 2"
          >
            <div class="nevrology__aside-item-title">
              Терапия боли: упускаемый фокус внимания
            </div>
            <div class="nevrology__aside-item-arrow">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 6L27 16L17 26"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M25 16L5 16"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
          <div class="nevrology__body-item" v-if="activeItem === 2">
            <div class="nevrology__body-item-banner">
              <img
                class="nevrology__body-item-banner-img"
                :src="require('@/assets/img/nevro/3.jpg')"
              />
              <div class="nevrology__body-item-banner-title">
                Терапия боли: упускаемый фокус <br />внимания
              </div>
            </div>
            <div class="nevrology__body-item-text">
              Практику современного невролога трудно представить без
              нестероидных противовоспалительных препаратов (НПВП) из-за их
              мультифакторной эффективности при болевом синдроме воспалительного
              генеза. К сожалению, клинические достоинства НПВП ограничены
              риском серьезных осложнений со стороны желудочно-кишечного тракта.
              <br />
              <br />
              Как с этим справиться в условиях реальной клинической практики?
              Какая роль эзомепразола в профилактике НПВП-гастропатий? На что
              обратить внимание при выборе ИПП? Об этом расскажем в проекте.
            </div>
            <a
              href="https://az-most.ru/medication/nexium"
              target="_blank"
              class="
                nevrology__body-item-button
                button button_pink
                d-inline-flex
                mt-auto
              "
            >
              Подробнее
            </a>
          </div>
        </div>
      </div>
      <div class="nevrology__text">
        АГ — артериальная гипертензия <br />
        ХСН — хроническая сердечная недостаточность <br />
        СД2 — сахарный диабет 2-го типа <br />
        ХБП — хроническая болезнь почек <br />
        ФП — фибрилляция предсердий <br />
        иНГЛТ2 — ингибиторы натрий-глюкозного котранспортера 2-го типа
        <br />
        <br />
        1. Никитин С.С., Бардаков С.Н., Супонева Н.А. и др. Фенотипическая
        гетерогенность и особенности диагностики транстиретинового амилоидоза с
        полинейропатией. Нервно-мышечные болезни 2021;11(3):12–36. DOI:
        10.17650/2222‑8721‑2021‑11‑3‑12‑36.
        <br />
        2. Hawkins P.N. et al. Ann Med. 2015;47(8):625-638.
        <br />
        3. Witteles R.M. et al. JACC Heart Fail. 2019;7(8):709-716.
        <br />
        4. Постникова Е. С. Рено-кардио-церебральный континуум у больных
        хроническими заболеваниями почек и артериальной гипетензией, осложненной
        ишемическим инсультом. Рациональные пути фармакокоррекции // ВНМТ. 2009.
        №2.
        <br />
        5. García-Donaire JA, Ruilope LM. Cardiovascular and Renal Links along
        the Cardiorenal Continuum. Int J Nephrol. 2011;2011:975782. doi:
        10.4061/2011/975782. Epub 2011 Mar 31. PMID: 21603119; PMCID:
        PMC3097083.
        <br />
        6. Kondo T., Abdul-Rahim A.H., Talebi A. et al. Predicting stroke in
        heart failure and reduced ejection fraction without atrial fibrillation.
        Eur Heart J. 2022 Nov 7;43(42):4469-4479. doi:
        10.1093/eurheartj/ehac487. PMID: 36017729; PMCID: PMC9637422.
        <br />
        7. Ueki K., Matsuo R., Kuwashiro T., Irie F., Wakisaka Y., Ago T.,
        Kamouchi M., Kitazono T; Fukuoka Stroke Registry Investigators.
        Decreased Estimated Glomerular Filtration Rate and Proteinuria and
        Long-Term Outcomes After Ischemic Stroke: A Longitudinal Observational
        Cohort Study. Stroke. 2023 May;54(5):1268-1277. doi:
        10.1161/STROKEAHA.122.040958. Epub 2023 Apr 6. PMID: 37021567.
        <br />
        8. Шестакова М.В., Галстян Г.Р., Гринева Е.Н. и др. Роль канаглифлозина
        в профилактике инсульта и хронической болезни почек у пациентов с
        сахарным диабетом 2 типа: резолюция совета экспертов. Сахарный диабет.
        2023;26(5):484-491.
        <a href="https://doi.org/10.14341/DM13086" target="_blank"
          >https://doi.org/10.14341/DM13086</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import TopBlock from "../../components/pageComponents/TopBlock.vue";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
export default {
  name: "SovremennayaNevrologiya",
  components: {
    TopBlock,
    Breadcrumbs,
  },
  data: () => ({
    activeItem: 0,
  }),
  methods: {
    changeItem(ind) {
      this.$set(this, "activeItem", ind);
      setTimeout(() => {
        document
          .querySelector(".nevrology__body-item")
          .scrollIntoView({ block: "start", behavior: "auto" });
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.nevrology {
  &__row {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 384px 1fr;
    column-gap: 35px;

    @media screen and (max-width: 1220px) {
      margin-top: 32px;
      display: block;
    }
  }

  &__aside {
    @media screen and (max-width: 1220px) {
      display: none;
    }
    &-title {
      margin-bottom: 70px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 22px;
      color: #000000;

      @media screen and (max-width: 1220px) {
        margin-bottom: 32px;
      }
    }

    &-item {
      padding: 32px;
      width: 100%;
      height: 229px;
      border-radius: 32px;
      border: 3px solid #154284;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      cursor: pointer;
      transition: .3s;

      &:hover {
        @media screen and (min-width: 1220px) {
          background-color: #15428485;
          .nevrology {
            &__aside {
              &-item {
                &-title {
                  color: #fff;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 1220px) {
        height: auto;
      }

      &:not(:last-child) {
        margin-bottom: 32px;
        @media screen and (max-width: 1220px) {
          margin-bottom: 24px;
        }
      }

      &-title {
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 29px;
        color: #1f1f1f;
        @media screen and (max-width: 1220px) {
          margin-bottom: 24px;
        }
      }

      &-arrow {
        margin-top: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        background-color: #830051;
        border-radius: 999px;
        cursor: pointer;
        transition: 0.3s;
        flex-shrink: 0;

        &:hover {
          background-color: #9a3374;
        }

        @media screen and (max-width: 1220px) {
          margin-top: 0;
        }
      }

      &_active {
        background-color: #154284;

        .nevrology {
          &__aside {
            &-item {
              &-title {
                margin: auto 0;
                color: #fff;
              }
              &-arrow {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &__body {
    border-radius: 32px;
    padding: 40px;
    background-color: #f2f2f2;
    @media screen and (max-width: 1220px) {
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: flex-start;
      @media screen and (max-width: 1220px) {
        background-color: #f2f2f2;
        border-radius: 20px;
        padding: 0 32px 32px;
        margin-bottom: 32px;
      }
      &-banner {
        width: 100%;
        height: auto;
        border-radius: 32px;
        overflow: hidden;
        position: relative;
        margin-bottom: 24px;

        @media screen and (max-width: 1220px) {
          margin: 0 -32px 24px;
          width: auto;
          min-height: 116px;
          border-radius: 20px;
        }

        &-img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: center;
        }

        &-title {
          position: absolute;
          top: 32px;
          left: 32px;
          z-index: 2;
          font-family: "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 32px;
          color: #ffffff;

          @media screen and (max-width: 1220px) {
            font-family: "Roboto Slab", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 26px;
            color: #ffffff;

            & > br {
              display: none;
            }
          }
        }
      }

      &-text {
        margin-bottom: 24px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3c4242;
      }
    }
  }

  &__text {
    padding-top: 64px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;

    @media screen and (max-width: 1220px) {
      padding-top: 32px;
    }
  }
}

sub,
sup {
  vertical-align: super;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: x-small;
  line-height: 22px;
  // color: #830051;
  color: inherit;
  white-space: nowrap;
}
</style>

<style lang="scss" >
.nevrology-banner {
  @media screen and (max-width: 1220px) {
    & .top-block__image-container {
      position: absolute;
    }

    & .top-block__title {
      color: #fff;
    }

    & .top-block__description {
      color: #fff;
      border-top: 1px solid #fff;
    }
  }

  @media screen and (max-width: 767px) {
    & .top-block__description {
      margin-top: 16px;
    }
  }
}
</style>